/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.0 Families
2.0 Weights
3.0 Sizes
4.0 Leadings
5.0 Trackings
--------------------------------------------------------------*/

/*--------------------------------------------------------------
1.0 Families
--------------------------------------------------------------*/

$font-family-primary: 'HK Grotesk', sans-serif;

/*--------------------------------------------------------------
2.0 Weights
--------------------------------------------------------------*/

// $font-weight-thin: 100;
// $font-weight-extralight: 200;
// $font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
// $font-weight-bold: 700;
// $font-weight-extrabold: 800;
// $font-weight-black: 900;

/*--------------------------------------------------------------
3.0 Sizes
--------------------------------------------------------------*/
$font-size-xs: 11px;
$font-size-small: 12px;
$font-size-medium: 16px;
$font-size-large: 20px;
$font-size-xl: 24px;
$font-size-xxl: 32px;
$font-size-xxxl: 48px;
$font-size-xxxxl: 56px;

/*--------------------------------------------------------------
4.0 Leadings
--------------------------------------------------------------*/

$font-line-height-small: 20px;
$font-line-height-medium: 24px;
$font-line-height-large: 28px;
$font-line-height-xl: 32px;
$font-line-height-xxl: 36px;
$font-line-height-xxxl: 52px;
$font-line-height-xxxxl: 64px;

/*--------------------------------------------------------------
5.0 Trackings
--------------------------------------------------------------*/

// $font-letter-spacing-tight: 0;
// $font-letter-spacing-normal: 0;
// $font-letter-spacing-loose: 0;
