/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.0 General

2.0 Elements
  2.1 Navbar
  2.2 View
  2.3 Button
  2.4 Category Header
  2.5 Category Item Card
  2.6 Account Header
--------------------------------------------------------------*/

/*--------------------------------------------------------------
1.0 General
--------------------------------------------------------------*/
$layout-min-width: 375px;

$layout-unit: 4px;

$layout-unit-xxxs: 8px;
$layout-unit-xxs: 12px;
$layout-unit-xs: 16px;
$layout-unit-small: 20px;
$layout-unit-medium: 24px;
$layout-unit-large: 30px;
$layout-unit-large-2: 40px;
$layout-unit-xl: 40px;
$layout-unit-xxl: 48px;

$layout-unit-xl-2: 80px;
$layout-unit-xxl-2: 120px;
$layout-unit-xxxl: 152px;

$layout-default-border-radius: 8px;

$layout-default-border-radius-marketing: 10px;

$layout-icon-default-width: 20px;
$layout-icon-default-height: $layout-icon-default-width;

/*--------------------------------------------------------------
2.0 Elements
  2.1 Navbar
--------------------------------------------------------------*/

$layout-navbar-content-height-xs: 100px;
$layout-navbar-content-height-xs-authenticated: 89px;

$layout-navbar-content-height: 40px;
$layout-navbar-vertical-padding: $layout-unit-medium;
$layout-navbar-border-width: 1px;

$layout-navbar-marketing-website-height: 81px;
$layout-navbar-marketing-website-height-mobile: 61px;

/*--------------------------------------------------------------
2.0 Elements
  2.2 View
--------------------------------------------------------------*/

$layout-view-vertical-padding: 48px;

/*--------------------------------------------------------------
2.0 Elements
  2.3 Button
--------------------------------------------------------------*/

$layout-button-padding: $layout-unit-xxs;
$layout-button-border-radius: $layout-default-border-radius;
$layout-button-border-width: 1px;
$layout-button-icon-margin: 8px;

$layout-pill-padding-vertical: 4px;
$layout-pill-padding-horizontal: 10px;

/*--------------------------------------------------------------
2.0 Elements
  2.4 Category Header
--------------------------------------------------------------*/

$layout-category-header-margin-bottom: 40px;

/*--------------------------------------------------------------
2.0 Elements
  2.5 Cards
--------------------------------------------------------------*/

$layout-category-item-card-height: 282px;
$layout-category-item-card-label-border-radius: 4px;

$layout-order-card-padding: $layout-unit-medium;
$layout-order-card-padding: $layout-unit-medium;
$layout-order-card-image-height: 168px;
$layout-order-card-list-height: 120px;
$layout-order-card-image-height--full: 260px;

/*--------------------------------------------------------------
2.0 Elements
  2.6 Account Header
--------------------------------------------------------------*/

$layout-account-header-avatar-height: 120px;
$layout-account-header-avatar-width: 120px;
$layout-account-header-image-height: 200px;
