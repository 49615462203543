/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.0 Colors
2.0 Contexts
3.0 Elements
  3.1 Button
  3.2 Card
  3.3 Header
--------------------------------------------------------------*/

/*--------------------------------------------------------------
1.0 Colors
--------------------------------------------------------------*/

$color-black: #000000;
$color-gray1: #161633;
$color-gray2: #414152;
$color-gray3: #777785;
$color-gray4: #B4B4B8;
$color-gray5: #DCDCE0;
$color-gray6: #F2F2F5;
$color-white: #FFFFFF;

$color-red1: #ec5344;
$color-red2: #C94337;
$color-red3: #E04B3D;
$color-red4: #F7BAB4;
$color-orange1: #E69635;
$color-orange2: #F5D5AE;
$color-green1: #21A380;
$color-green2: #42B898;
$color-green3: #6ACCB2;
$color-green4: #BCE3D8;
$color-blue1: #2490B8;
$color-blue2: #31A3CC;
$color-blue3: #75C4E0;

/*--------------------------------------------------------------
2.0 Contexts
--------------------------------------------------------------*/

$color-primary: $color-red1;

$color-content: $color-gray1;
$color-background: $color-white;

$color-text-alternative: $color-gray3;

/*--------------------------------------------------------------
3.0 Elements
--------------------------------------------------------------*/

/*--------------------------------------------------------------
3.0 Elements
  3.1 Button
--------------------------------------------------------------*/

$color-button-background: $color-gray1;
$color-button-text: $color-white;
$color-button-light-background: $color-gray6;
$color-button-light-text: $color-content;
$color-button-light-border: $color-gray5;

$color-button-danger-background: $color-red1;
$color-button-danger-text: $color-white;

/*--------------------------------------------------------------
3.0 Elements
  3.2 Cards
--------------------------------------------------------------*/

$color-order-card-background: $color-white;
$color-order-card-border: $color-gray5;
$color-order-card-footer-background: $color-gray6;

$color-order-card-list-item-first-background: $color-white;
$color-order-card-list-item-first-border: $color-blue2;

$color-order-card-list-item-last-background: $color-red1;
$color-order-card-list-item-last-border: $color-red4;
$color-order-card-list-item-line: $color-gray5;
$color-order-card-list-item-square: $color-gray5;

$color-order-card-list-item-quantity-background: $color-gray6;
$color-order-card-list-item-quantity-border: $color-gray5;

/*--------------------------------------------------------------
3.0 Elements
  3.2 Header
--------------------------------------------------------------*/

$color-orders-header-navigation: $color-gray4;
$color-orders-header-navigation-active: $color-red1;
