@import "~styles/prepend";
.app-privacy-area {
  padding-top: $layout-unit-large;

  //.app-privacy-area__title
  &__title {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  //.app-privacy-area__description
  &__description {
    margin-top: 8px;
    margin-bottom: 10px;
    color: $color-text-alternative;
  }

  &__subdescription {
    margin-top: 2px;
    margin-bottom: 2px;
    color: $color-text-alternative;
  }

  &__subtitle {
  text-transform: uppercase;
  margin-bottom: 0;
  }
}

.app-privacy-form {

   //.reset-password-form:not(:last-of-type)
   &__item:not(:last-of-type) {
    margin-bottom: 24px;
  }

  //.app-privacy-form__footer
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: $layout-unit-medium;
  }

  //.app-privacy-form__link
  &__link {
    color: $color-primary;
    text-decoration: underline;
    font-size: 14px;
  }

  
}
