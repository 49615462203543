/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.0 Fonts
2.0 Styles
3.0 Utilities
--------------------------------------------------------------*/

/*--------------------------------------------------------------
1.0 Fonts
--------------------------------------------------------------*/

// @font-face {
//   font-family: 'HK Grotesk';
//   src: url('../assets/fonts/hk-grotesk/HKGrotesk-Regular.otf');
//   font-style: normal;
//   font-weight: 400;
// }

// @font-face {
//   font-family: 'HK Grotesk';
//   src: url('../assets/fonts/hk-grotesk/HKGrotesk-Medium.otf');
//   font-style: normal;
//   font-weight: 500;
// }

// @font-face {
//   font-family: 'HK Grotesk';
//   src: url('../assets/fonts/hk-grotesk/HKGrotesk-SemiBold.otf');
//   font-style: normal;
//   font-weight: 600;
// }

/*--------------------------------------------------------------
2.0 Styles
--------------------------------------------------------------*/

a {
  color: inherit;
  text-decoration: none;

  //a:active,
  //a.active
  &:active,
  &.active {
    color: inherit;
  }

  //a:disabled,
  //a.disabled
  &:disabled,
  &.disabled {
    cursor: not-allowed;
    color: inherit;
  }
}

ul {
  margin: 0;
  padding: 0;

  list-style: none;
}

figure {
  margin: 0;
}

/*--------------------------------------------------------------
3.0 Utilities
--------------------------------------------------------------*/

.position-relative {
  position: relative;
}

.no-scroll {
  overflow: hidden;
}

.underline {
  text-decoration: underline;
}
